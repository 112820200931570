<template>
  <v-navigation-drawer
    v-model="sidebarMenu"
    :mini-variant="mini"
    width="210"
    permanent
    floating
    app
    class="myPrimary full-height"
  >
    <v-list-item class="px-2 my-6" @click.stop="toggleMini = !toggleMini">
      <v-list-item class="pl-0">
        <v-list-item-icon
          class="rounded d-flex justify-center align-center flex-column main-logo"
        >
          <img
            src="@/assets/5d4a964ed9a508345a363bdf_GDSS.png"
            alt="logo"
            width="32"
            class="main-logo"
          />
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text"> </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-btn icon @click.stop="toggleMini = !toggleMini">
        <v-icon color="white">mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>

    <v-list dense>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        class="pl-2 mb-6"
        :to="item.link"
      >
        <v-list-item-icon
          class="rounded d-flex justify-center align-center myBorder mySecondary--text"
        >
          <v-icon class="white--text" small>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="white--text">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- LOGOUT PREVIEW -->

      <v-dialog v-model="dialog" persistent max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item class="pl-2 mb-6" v-bind="attrs" v-on="on">
            <v-list-item-icon
              class="rounded d-flex justify-center align-center myBorder"
            >
              <v-icon small>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="white--text">Keluar</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-card>
          <v-card-title class="text-h5">
            Apakah Anda yakin untuk keluar?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialog = false">
              TIDAK
            </v-btn>
            <v-btn color="green darken-1" text @click="logout"> YA </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import router from "../../router";

export default {
  data() {
    return {
      dialog: false,
      sidebarMenu: true,
      toggleMini: false,
      items: [
        { title: "Dashboard", icon: "mdi-file-outline", link: "/dashboard" },
        { title: "Inventori", icon: "mdi-archive-outline", link: "/inventory" },
        {
          title: "Checkout",
          icon: "mdi-truck-check-outline",
          link: "/checkout",
        },
        { title: "Cetak", icon: "mdi-printer", link: "/print" },
        {
          title: "Daftar RFID",
          icon: "mdi-clipboard-list-outline",
          link: "/rfid",
        },
        {
          title: "Verifikasi",
          icon: "mdi-bookmark-outline",
          link: "/verification",
        },
        { title: "Cabang", icon: "mdi-new-box", link: "/new_branch" },
        // { title: "Harga Emas", icon: "mdi-gold", link: "/gold-price" },
        { title: "Account", icon: "mdi-account", link: "/account" },
      ],
    };
  },
  computed: {
    mini() {
      return this.toggleMini;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      router.push("/");
    },
  },
};
</script>

<style scoped>
.full-height {
  height: 100vh;
}
.myBorder {
  border: 1px solid #6488bb;
}
.main-logo {
  border-radius: 5px;
  border-color: black;
  box-shadow: #144444;
}
.v-list-item--dense .v-list-item__icon,
.v-list--dense .v-list-item .v-list-item__icon {
  height: 36px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.v-list-item__action,
.v-list-item__avatar,
.v-list-item__icon {
  min-width: 36px;
}

.v-list-item--active .v-list-item__icon {
  background: #6488bb;
}

.theme--light.v-list-item--active::before {
  opacity: 0;
}

.v-list-item__icon i {
  color: white;
}

.v-list-item--active .v-list-item__icon i {
  color: #144444;
}
</style>
